import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper jrhtw9ph4a-editor_css' },
  page: { className: 'home-page m2x0n4pbsyk-editor_css' },
  logo: {
    className: 'header2-logo m2x0hetwz1-editor_css',
    children: 'https://s21.ax1x.com/2024/10/24/pAwKMUe.png',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Banner3_0',
        children: '首页',
        className: 'menu-item m2mwvcm2f7p-editor_css',
      },
      {
        name: 'linkNav~m2km0opv1hh',
        to: 'Feature0_2',
        children: '企业实力',
        className: 'menu-item m2mwvf7nkpg-editor_css',
      },
      {
        name: 'linkNav~m2klohm7d26',
        to: 'Feature0_0',
        children: '企业发展',
        className: 'menu-item m2mwvhb96y-editor_css',
      },
      {
        name: 'linkNav~m2lv3706ft8',
        to: 'Content5_0',
        children: '企业荣誉',
        className: 'menu-item m2mwvjbut49-editor_css',
      },
      {
        name: 'linkNav~m2kloscpqdj',
        to: 'Feature0_1',
        children: '产品介绍',
        className: 'menu-item m2mwvl1ycsp-editor_css',
      },
      {
        name: 'linkNav~m2lv64uuvd',
        to: 'Content13_0',
        children: '合作共赢',
        className: 'menu-item m2mwvmvdsh-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
  Menu: {
    children: [
      {
        name: 'Banner3_0',
        to: 'Banner3_0',
        children: '首页',
        className: 'active menu-item',
      },
      {
        name: 'Content8_0',
        to: 'Content8_0',
        children: '特邀嘉宾',
        className: 'menu-item',
      },
      {
        name: 'Content9_0',
        to: 'Content9_0',
        children: '会议日程',
        className: 'menu-item',
      },
      {
        name: 'Content10_0',
        to: 'Content10_0',
        children: '大会地址',
        className: 'menu-item',
      },
      {
        name: 'Content11_0',
        to: 'Content11_0',
        children: '展台展示',
        className: 'menu-item',
      },
      {
        name: 'Content12_0',
        to: 'Content12_0',
        children: '特别鸣谢',
        className: 'menu-item',
      },
    ],
  },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3 m2klnzl4avq-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper m2kla15mqpk-editor_css',
    children: [
      {
        name: 'name~m2lgwa4bht',
        className: 'banner3-name m2mwv4wfd1k-editor_css',
        children: (
          <span>
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
};
export const Feature02DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>企业实力</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper m3593j4g47k-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m3596jf508k-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAs8Uht.jpg',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      库姆巴特啤酒（湖北）有限公司，是一家立志成为国内健康精酿啤酒厂牌的新锐企业。公司总投资7.12亿元，在湖北省仙桃市食品药品产业园区建设了占地170余亩的啤酒花园小镇式生产基地。公司由广州轻工业商会会长廖启元先生创办，2021年与德国库姆巴特公司在北京签订了技术和商标的转让文件；2024年3月一期工程车间落成投产，预计年产酿两万余吨。
                    </p>
                  </span>
                </span>
              ),
              className: 'm3594z47k2-editor_css',
            },
            {
              name: 'content~m3595na1ya',
              className: 'm3595prh7ab-editor_css',
              children: (
                <span>
                  <p>
                    公司目前配有奥地利H.D.Eckhardt侧拉盖设备、德国西塞铝瓶旋口设备、软木塞盖玻璃瓶封装设备等多种欧美进口设备，打造国内领先的精酿啤酒品质保障技术和生产研发能力。公司特聘德国和国内国家级的顶级酿酒师组成的技术团队，研发适合中国人口味的精酿啤酒。严选欧美进口麦芽、酒花、酵母，融合多种中华药食同源草本精华以小罐精工酿造、艾尔发酵、野菌发酵、过桶陈酿等多种精酿啤酒酿造工艺。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>
                <span>企业发展</span>
                <br />
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper m2km6cz1mq8-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block m2kmgoo2gb-editor_css',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item m2kmdsbxebr-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m2mw4nxayam-editor_css',
              children: 'https://s21.ax1x.com/2024/10/24/pAwK1Cd.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title m358xrw6mne-editor_css',
              children: (
                <span>
                  <p>啤酒主体小镇</p>
                  <p>产游一体</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    公司于2024年10月启动二期啤酒窖藏馆的建设，啤酒博物馆、体验馆、游乐馆的建设将依期推进，三年内打造出一个江汉平原上的AAA级啤酒花园小镇。
                  </p>
                </span>
              ),
              className: 'm2kmjfe4ng-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block m2km9yaz4k8-editor_css',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m2mw2itxfa8-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAs8NtI.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title m358y2ed83q-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>一线城市合作加盟</p>
                        <p>
                          强强联手&nbsp;<span>合作共赢</span>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      2024年优先布局湖北、广东、河南三省,采用自营旗舰店带动加盟店、店群影响精酿啤酒圈层的策略进行入市
                      以瓶装啤酒、桶装生啤酒和特许经营门店三种形式逐步向北京、上海、西安、成都、深圳等一线城市辐射打
                      造一线高端精酿啤酒品牌。&nbsp;
                    </p>
                    <p>
                      2025年目标全国布局自营门店+特许经营门店100家,其中100m2以上面积的旗舰型门店超30%,与竞争对手
                      形成品牌档次上的领先优势;精心打磨连锁经营6大服务模型，确保门店经营上的战略优势。
                    </p>
                  </span>
                </span>
              ),
              className: 'm2km7i990co-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper m2lza0d2q7-editor_css',
  },
  page: { className: 'home-page content5 m3599rv32ee-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>企业荣誉</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper m2mx1pi9uwt-editor_css',
    gutter: 16,
    children: [
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsadaD.jpg',
            className: 'm358l0ve9q-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>CCTV-1 综合频道</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mwe3prygb',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsawIe.jpg',
            className: 'm358nh7kz6-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CCTV-2 财经频道</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mwe5dj36',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsarRA.jpg',
            className: 'm358nomk9dm-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CCTV-4 中文国际频道</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mwe63ytaa',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsasxI.jpg',
            className: 'm358nw65c8r-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CCTV-7 国防军事频道</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mwe79mjll',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsa6Mt.jpg',
            className: 'm358o27fi4-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CCTV-8 电视剧频道</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mx1r2redc',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsabLV.jpg',
            className: 'm358o9x110e-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CCTV-12 社会与法频道</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mx1sooskc',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsaLZT.jpg',
            className: 'm358occm7wg-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CCTV-13 新闻频道</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m2mx1zvrrfi',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsaOdU.jpg',
            className: 'm358oh76skc-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>CCTV-15 音乐频道</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsaBPH.jpg',
            className: 'm358qg0geie-editor_css',
          },
          content: {
            children: (
              <span>
                <p>德国 iF 设计奖</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsaDGd.png',
            className: 'm358qoljbyb-editor_css',
          },
          content: {
            children: (
              <span>
                <p>STC优正认证</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s21.ax1x.com/2024/11/05/pAsaaVO.jpg',
            className: 'm358rneocv-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>香港公司注册登记</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>产品介绍</p>
          </span>
        ),
      },
      {
        name: 'content~m347sgytjnt',
        className: 'm347shx0kuj-editor_css',
        children: (
          <span>
            <span>
              <p>
                采用两种特殊进口麦芽，融合多种药食同源草本精粹，酿成淡色清爽朦胧酒体，香蕉的水果香和丁香的酵母香层次释放，饮之有明显的杀口感，口感松软、轻柔、奶油般饱满感，收口干净，没有杂味。
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper m359cjlj83q-editor_css',
    children: [
      {
        name: 'block~m346to1k5s7',
        className: 'content0-block',
        md: 8,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m347mas12z9-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAstzRI.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>健I - 精酿啤酒</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>净含量 688ml</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m347kxl90zg-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAsNSzt.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>健I - 精酿啤酒</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>净含量 450ml</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m347eg9ujtt-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAstxJA.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>健I - 精酿啤酒</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>净含量 275ml</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~m346tq39opq',
        className: 'content0-block',
        md: 8,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m347p2xzhp9-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAsNeWn.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>健I - 精酿啤酒</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>净含量 2000ml</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~m346tpidvkj',
        className: 'content0-block',
        md: 8,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m347mz0qicc-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAsNCsf.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>健I - 精酿啤酒</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>净含量 750ml</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon m347ifd8qzd-editor_css',
              children: 'https://s21.ax1x.com/2024/11/05/pAsN9QP.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>健I - 精酿啤酒</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>净含量 390ml</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper m359ghztu5-editor_css',
  },
  OverPack: { className: 'home-page content6 m359b2vd6hi-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 15 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>产品原料</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  img: {
    children: 'https://s21.ax1x.com/2024/10/24/pAwKQ4H.png',
    className: 'content6-img m359fp5qdbd-editor_css',
    xs: 24,
    md: 9,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://s21.ax1x.com/2024/11/05/pAsKtk6.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>欧洲进口麦芽</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    采用全球顶级的德国城堡麦芽，麦香浓郁，酿酒自带香甜风味。已建立战略合作伙伴关系，确保了产品的品质和稳定性
                  </p>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children: 'https://s21.ax1x.com/2024/11/05/pAsKNtK.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>美国进口啤酒花</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>酿酒自带花香、果香等自然芬芳，滋味丰富饱满层次分明</p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children: 'https://s21.ax1x.com/2024/11/05/pAsKJTx.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>中华药食同源草本精华</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  融合中华药食同源食养文化与健康饮酒文化酿成中国特色，加入葛根、百合、恰玛古、栀子，滴滴醇正，真正做到零添加、低嘌呤的健康精酿
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
    className: 'm2lz0hzkgr-editor_css',
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper m2lvi71k9hq-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper m348fe699k-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>合作共赢</p>
          </span>
        ),
        className: 'title-h1 m2ng4s3bk2-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>招商热线：400-0079-179 &nbsp;&nbsp;&nbsp;&nbsp; 027-86652188</p>
          </span>
        ),
        className: 'title-content m2ng2wwp24l-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <p>
                公司地址：湖北省仙桃市G318国道98号
              </p>
            </span>
          </span>
        ),
        className: 'title-content m2nfv1ij3qs-editor_css',
      },
      {
        name: 'content~m2lvjw30djl',
        className: 'm2ng7mp6uv-editor_css',
        children: (
          <span>
            <p>运营中心：湖北省武汉市武昌区德成中心 2710-14 室</p>
          </span>
        ),
      },
      {
        name: 'content~m348oh711xm',
        className: '',
        children: (
          <span>
            <span>
              <div>
                <br />
              </div>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                © 2024 库姆巴特啤酒（湖北）有限公司 版权所有 鄂ICP备2024079704号<br />
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
};
